/*

  File: index.js
  Kind: Page
  Description: Page banner with title and subtitle that are read from the site config

*/

import * as React from "react"


//Components
import Layout from "../components/layout.js"


// markup
const IndexPage = () => {
  return (
    <Layout>
      <div className="px-4 py-6 sm:px-0">
        <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">
          see-me placeholder
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
